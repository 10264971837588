
<main>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'checkout.winkelmand-titel'| translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="text-center">
              <b>{{'checkout.count-label'| translate }}</b>
            </th>
            <th scope="col">
              <b>{{'checkout.product-label'| translate }}</b>
            </th>
            <th scope="col">
              <b>{{'checkout.prijs-label'| translate }}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of newOrder.productList" class="basket-card-tr-font">
            <th class="text-center basket-card-th-width">
              <div>{{item.qty}}x</div>
              <div class="basket-card-count-icons-margin">
              </div>
            </th>
            <td class="basket-card-product-name-width">
              [{{item.menucat_name}}] {{item.menu_name}}
              <br>
              <div class="clr-orange">{{generateExtrasTextBasketItem(item.childs)}}</div>
            </td>
            <td>€ {{ calculateItemPrice(item.qty,(newOrder.isPickup ? item.to_menu_price : item.menu_price)) | number : '1.2-2'}}</td>
          </tr>
          <tr *ngIf="newOrder.productList != null && newOrder.productList.length <= 0">
            <td colspan="4">{{'checkout.winkelmand-leeg-label'| translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span *ngIf="newOrder.productList != null && newOrder.productList.length != 0" class="text-center">
      <hr>
      <div class="row">
        <div class="col-6">
          {{'checkout.subtotaal-label'| translate }}
          <br *ngIf="newOrder.discountC > 0">
            <span *ngIf="newOrder.discountC > 0" class="text-danger">{{'checkout.discount-label'| translate }}</span>
            <br>
            {{'checkout.delivery-label'| translate }}
          <span *ngIf="newOrder.transactionPrice != 0">
            <br>{{'checkout.transaction-label'| translate }}</span>
          <br>
          <b>{{'checkout.totaal-label'| translate }}</b>
        </div>
        <div class="col-6">
          € {{newOrder.subtotal_before | number : '1.2-2'}}
          <br *ngIf="newOrder.discountC > 0">
          <span *ngIf="newOrder.discountC > 0" class="text-danger">- € {{newOrder.discountC | number : '1.2-2'}}</span>
          <br>
          <span *ngIf="newOrder.isPickup">{{'checkout.gratis-afhalen-label'| translate }}</span>
          <span *ngIf="newOrder.deliveryPrice <= 0 && !newOrder.isPickup">{{'checkout.gratis-label'| translate }}</span>
          <span *ngIf="newOrder.deliveryPrice > 0 && !newOrder.isPickup">€ {{newOrder.deliveryPrice | number : '1.2-2'}}</span>
          <span *ngIf="newOrder.transactionPrice != 0">
            <br> € {{newOrder.transactionPrice | number : '1.2-2'}}</span>
          <br> € {{newOrder.totalPrice | number : '1.2-2' }}
        </div>
        <br *ngIf="newOrder.discountC > 0">
        <span *ngIf="newOrder.discountC > 0" class="text-danger discount-text">{{'checkout.online-discount-label'| translate: {kortingProcent: newOrder.discountP | number : '1.0-0'} }} <br> {{'checkout.bespaart-zo-label' | translate: {bedrag: newOrder.discountC | number : '1.2-2'} }}</span>
      </div>
      <hr>
      <div>
        {{basketErrorMessage}}
      </div>
    </span>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-orange" (click)="close(); scrollto()">{{'checkout.bestellen-label'| translate }}</button>
  </div>
</ng-template>
<div class="container-fluid bestelling">
    <div class="container ">
      <!-- Cart -->

  <div class="btn-group cart" >
      <button class="btn btn-secondary cart-btn"
        type="button" (click)="open(content)">
        <span id="aantal" class="number" *ngIf="newOrder.productList != null && newOrder.productList.length != 0">{{newOrder.productList.length}}</span>
        <i class="icon-shopping-basket"></i>
        <span *ngIf="newOrder.productList != null && newOrder.productList.length != 0">€ {{ newOrder.totalPrice | number : '1.2-2'}}</span>
      </button>
    </div>
    <h2 class="text-center">{{'checkout.bijna-klaar-label'| translate }}</h2>
    </div>
</div>

<div class="container-fluid bestelling">
  <div class="container ">

    <div class="formafronden">
      <span *ngIf="dataFactory.restaurant.isDeliveringToday != 0">
        <h3>{{'checkout.afhalen-bezorgen-label'| translate }}</h3>
        <hr>
        <div class="container row text-center">
          <label class="radio-inline col-6" [title]="getCustomTitleForDeliveryOptions()">
            <input class="item-opacity" name="options" (change)="changeDeliveryType();" [disabled]="dataFactory.restaurant.isDeliveringToday == 0" ng-control="options"
              type="radio" [value]="false" [(ngModel)]="newOrder.isPickup">
            <br/>{{'checkout.bezorgen-label'| translate }}
          </label>
          <label class="radio-inline col-6">
            <input class="item-opacity" name="options" (change)="changeDeliveryType();" ng-control="options" type="radio" [value]="true" [(ngModel)]="newOrder.isPickup">
            <br/>{{'checkout.afhalen-label'| translate }}
          </label>
        </div>
      </span>
      <br>
      <form #orderForm="ngForm" name="orderForm">
        <span *ngIf="!newOrder.isPickup">
          <h3>{{'checkout.waar-label'| translate }}</h3>
          <hr>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="required" for="iAddress">{{'checkout.straat-label'| translate }} <span *ngIf="erroraddressnumber" class="addressnotice">{{'checkout.huisnr-label'| translate }}</span></label>
              <input #inputaddress autocomplete="deliverAdres" type="text" name="deliverAdres" required class="form-control" [ngClass]="{ errorselect: (!newOrder.deliverAddress) }"
                id="iAddress" placeholder="{{'checkout.straat-label'| translate }}" [(ngModel)]="newOrder.deliverAddress" (blur)="checkaddressnumber(inputaddress.value)">
            </div>
            <div class="form-group col-md-2">
              <label class="required" for="ipostcode">{{'checkout.postcode-label'| translate }}</label>

              <input *ngIf="distinctDeliverZipCodes.length == 0" type="text" name="deliverpostcode" required class="form-control" [ngClass]="{ errorselect: (!newOrder.deliverZip) }"
                id="ipostcode" autocomplete="deliverpostcode" placeholder="{{'checkout.postcode-label'| translate }}" [(ngModel)]="newOrder.deliverZip">

              <select *ngIf="distinctDeliverZipCodes.length > 0" class="form-select" name="zipcode" (ngModelChange)="changeDeliverZip($event)"
                required [ngModel]="newOrder.deliverZip" [ngClass]="{ errorselect: (!newOrder.deliverZip) }" [attr.aria-label]="'checkout.postcode-label' | translate ">
                <option *ngFor="let zipcode of distinctDeliverZipCodes" [value]="zipcode.zcZip">{{zipcode.zcZip}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label class="required" for="iPlaats">{{'checkout.city-label'| translate }}</label>
              <input type="text" autocomplete="delivercity" name="delivercity" required class="form-control" id="iPlaats" placeholder="{{'checkout.city-label'| translate }}"
                [(ngModel)]="newOrder.deliverCity" [ngClass]="{ errorselect: (!newOrder.deliverCity) }">
            </div>
          </div>
        </span>
        <h3>{{'checkout.contact-label'| translate }}</h3>
        <hr>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="required" for="iname">{{'checkout.naam-label'| translate }}</label>
            <input type="text" autocomplete="name" class="form-control" required id="iname" name="name" placeholder="{{'checkout.naam-label'| translate }}"
              [(ngModel)]="newOrder.buyerName" [ngClass]="{ errorselect: (!newOrder.buyerName) }">
          </div>
          <div class="form-group col-md-6">
            <label class="required" for="iemail">{{'checkout.email-label'| translate }}</label>
            <input type="text" class="form-control" autocomplete="email" required id="iemail" name="email" placeholder="{{'checkout.email-label'| translate }}"
              [(ngModel)]="newOrder.buyerEmail" [ngClass]="{ errorselect: (!newOrder.buyerEmail) }">
          </div>
          <div class="form-group col-md-6">
            <label class="required" for="itel">{{'checkout.tel-label'| translate }}</label>
            <input type="text" class="form-control" autocomplete="tel" required id="itel" name="tel" placeholder="{{'checkout.tel-label'| translate }}"
              [(ngModel)]="newOrder.buyerTel" [ngClass]="{ errorselect: (!newOrder.buyerTel) }">
          </div>
          <div class="form-group col-md-6">
            <label for="iorganisatie">{{'checkout.org-label'| translate }}</label>
            <input type="text" class="form-control" id="iorganisatie" autocomplete="org" name="org" placeholder="{{'checkout.org-label'| translate }}" [(ngModel)]="newOrder.buyerOrg">
          </div>
        </div>
        <h3 *ngIf="newOrder.isPickup">{{'checkout.vraag-afhaaltijd-label'| translate }}</h3>
        <h3 *ngIf="!newOrder.isPickup">{{'checkout.vraag-bezorgtijd-label'| translate }}</h3>
        <hr>
        <div class="row">
          <div class="form-group col-md-6">
            <label class="required" for="ideliveryhour" *ngIf="newOrder.isPickup">{{'checkout.afhaaltijd-label'| translate }}</label>
            <label class="required" for="ideliveryhour" *ngIf="!newOrder.isPickup">{{'checkout.bezorgtijd-label'| translate }}</label>
            <select class="form-select mr-sm-2" id="ideliveryhour" required name="deliveryhour" [(ngModel)]="newOrder.deliveryHour"
              [ngClass]="{ errorselect: (!newOrder.deliveryHour) }">
              <option value="select" *ngIf="!newOrder.isPickup && !showAsapDeliverMethod">{{'checkout.select-bezorg-label'| translate }}</option>
              <option value="select" *ngIf="newOrder.isPickup && !showAsapDeliverMethod">{{'checkout.select-afhaal-label'| translate }}</option>
              <option value="asap" *ngIf="showAsapDeliverMethod">{{'checkout.zsm-label'| translate }}</option>
              <option *ngFor="let time of estimatedDeliverTimes" [value]="time">{{time}}</option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="icomment">{{'checkout.opmerking-label'| translate }}</label>
            <!-- <input type="text" class="form-control" id="inputAddress2" placeholder="Postcode"> -->
            <textarea class="form-control" rows="5" autocomplete="comment" id="icomment" name="comment" [(ngModel)]="newOrder.orderNotes"></textarea>

            <label class="savenotes"> {{'checkout.save-opmerking-label'| translate }}
            <input [(ngModel)]="newOrder.saveNotes" type="checkbox" name="savenotes">
            <span class="checkmark"></span>
            </label>

          </div>
        </div>

        <h3>{{'checkout.hoe-betalen-label'| translate }}</h3>
        <hr>

        <div id="paymentMethodsWithIcon" class="row payment-methods-row">
          <!-- payment-method-img-div-oneline-text -->
          <div class="payment-method-div" *ngFor="let method of dataFactory.paymentMethods" (click)="changePaymentMethod(method)" [ngClass]="{'payment-method-selected': (selectedPaymentMethod == method)}">
            <div class="payment-method-img-div">
              <img [src]="method.imgUrl">
            </div>
            <div class="payment-method-txt">
              {{method.pmName}}
            </div>
          </div>
        </div>

        <div class="paymentmethodsmobile" id="paymentMethodsWithoutIcon">
          <div class="pulldown_form">
            <select id="iselectpayment" class="form-control" name="selectpayment" [(ngModel)]="selectedPaymentMethod" (change)="changePaymentMethod(selectedPaymentMethod)" aria-label="Choose payment">
              <option *ngFor="let method of dataFactory.paymentMethods" [ngValue]="method" class="pulldown">{{method.pmName}}</option>
            </select>
          </div>
        </div>


        <div *ngIf="(selectedPaymentMethod != undefined && selectedPaymentMethod.pmStrId=='CNT' )">
          <div class="paymentdetails" id="ipaymentdetails">
            <div class="cashpulldown">
              <p class="mt-1">{{'checkout.betalen-met-label'| translate }}</p>
              <div class="idealpulldown">
                <div class="pulldown_form">
                  <select name="payswith" id="ipayswith" [(ngModel)]="newOrder.contantPaymentAmount" class="form-control">
                  <option value="0">{{'checkout.gepast-betalen-label'| translate }} (€ {{newOrder.totalPrice | number : '1.2-2'}})</option>
                    <option *ngFor="let amount of betaalmogelijkheden" [value]="amount" class="pulldown">€ {{amount | number : '1.2-2'}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-method-selected-notes">
          {{transactionCostText}}
        </div>

        <button id="ibestellen" type="submit" class="btn btn-orange mt-3 mb-3" (click)="createOrder()" [disabled]="!orderForm.valid">{{'checkout.bestellen-button'| translate }}</button>
      </form>

      <div class="alert alert-danger" *ngIf="errorMessages.length > 0">
        <b>{{'checkout.fouten-label'| translate }}</b>
        <ul>
          <li *ngFor="let err of errorMessages" [innerHTML]="err"></li>
          <a *ngIf="showRedirectLink" class="redirect-link" (click)="redirectRestList()">{{ 'checkout.redirect_link' | translate }}</a>
        </ul>
      </div>
    </div>
  </div>
</div>
</main>
