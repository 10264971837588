import { BasketList } from "./basketlList.model";

export class OrderForm {
    public deliverAddress: string = "";
    public deliverZip: string = "";
    public deliverCity: string = "";
    public buyerName: string = "";
    public buyerEmail: string = "";
    public buyerTel: string = "";
    public buyerOrg: string = "";
    public deliveryHour: string = "asap";
    public orderNotes: string = "";
    public paymentMethod: string = "";
    public totalPrice: number;
    public deliveryPrice: number;
    public subtotalPrice: number;
    public isPickup: boolean = false;
    public productList: BasketList[];
    public transactionPrice: number = 0;
    public contantPaymentAmount: number = 0;
    public referrer: string = "";
    public checkoutreferrer: string = "";
    public browser: string = "";
    public distance: string = "0";
    public subtotal_before: number;
    public discountP: number = 0;
    public discountC: number = 0;
    public saveNotes: boolean =false;
    public deliveryPriceAfter: number;

    public setPickupMethod(pickup: number) {
        if (pickup == 1) {
            this.isPickup = true;
            this.deliverCity = "";
        } else {
            this.isPickup = false;
        }
    }

    public setPrices(subtotal: number, delivery: number, subtotal_before: number) {
        this.subtotalPrice = subtotal;
        this.deliveryPrice = delivery;
        this.subtotal_before = subtotal_before;

        if (this.subtotalPrice == 0){
            this.discountC = 0;
        }

        this.totalPrice = +(this.subtotalPrice) + +(this.deliveryPrice);
    }

    public setDiscount(discountPercent: number, discountCash: number){
        this.discountC = discountCash;
        this.discountP = discountPercent;
    }
}
